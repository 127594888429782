<script setup lang="ts">
import ConfirmModal from '~/components/modals/ConfirmModal.vue';

const spellStore = useSpellsStore();
const { spell, trigger } = storeToRefs(spellStore);
const form = ref(null);
const modal = useModal();

const state = reactiveComputed(() => {
  return {
    name: spell.value?.name ?? undefined,
    description: spell.value?.description ?? undefined,
  };
});

const validate = (state: any): FormError[] => {
  const errors = [];
  if (!state.name) errors.push({ path: 'name', message: 'Required' });
  return errors;
};

async function handleDelete() {
  modal.open(ConfirmModal, {
    type: 'spell',
    action: 'delete',
    message: 'Are you sure you want to delete this spell?',
    isDangerous: true,
    async onConfirm() {
      await spellStore.deleteSpell(spell.value?.id);
      modal.close();
      await navigateTo('/');
    },
  });
}

const onSubmit = async (event: FormSubmitEvent) => {
  const { data: metadata } = event;
  const updates = { ...metadata };
  await spellStore.updateSpell(spell.value?.id, updates);
  await navigateTo({
    name: trigger.value ? 'spell.history' : 'spell.run',
    params: { spellId: spell.value?.id },
    replace: true,
  });
};
</script>
<template>
  <UForm
    ref="form"
    :validate="validate"
    :state="state"
    class="w-full max-w-3xl my-xl flex flex-col gap-6 items-start"
    @submit="onSubmit"
  >
    <p class="main-title">Settings</p>
    <UFormGroup
      eager-validation
      size="xl"
      name="name"
      required
      label="Name"
      class="w-full"
    >
      <UInput v-model="state.name" />
    </UFormGroup>
    <UFormGroup
      size="xl"
      name="description"
      label="Description"
      description="Describe the purpose of this spell. Markdown is supported."
      class="w-full"
    >
      <UTextarea
        v-model="state.description"
        autoresize
        resize
        :maxrows="20"
        size="xl"
      />
    </UFormGroup>
    <div class="flex flex-row w-full justify-between mt-l items-center">
      <UButton
        :ui="{ rounded: 'rounded-full', font: 'font-bold' }"
        size="xl"
        icon="i-ph-trash-simple"
        label="Delete Spell"
        variant="outline"
        color="red"
        @click="handleDelete"
      />
      <!-- TODO: Add template toggle -->
      <!-- <span class="flex gap-2">
        <p class="body">Template</p>
        <UToggle v-model="state.isTemplate" />
      </span> -->
      <UButton
        :ui="{ rounded: 'rounded-full', font: 'font-bold' }"
        size="xl"
        label="Save Changes"
        type="submit"
        :disabled="form?.errors?.length"
      />
    </div>
  </UForm>
</template>
